import React, { useReducer, useCallback } from "react";
import LoggedInUserContext from "./loggedInUserContext";
import LoggedInUserReducer from "./loggedInUserReducer";
import {
  GET_LOGGED_IN_USER,
  LOGGED_IN_USER_ALERT,
  CLEAR_LOGGED_IN_USER_ALERT,
} from "../types";
import PropTypes from "prop-types";
import api from "../../utils/api";
import { useTranslation } from "react-i18next";

const LoggedInUserState = (props) => {
  const initialState = {
    loggedInUser: {
      id: "",
      username: "",
      name: "",
      email: "",
      countryCode: "",
      phone: "",
    },
    loggedInUserAlert: null,
  };

  const [state, dispatch] = useReducer(LoggedInUserReducer, initialState);

  const { t } = useTranslation();

  const getLoggedInUser = useCallback(
    async (abortController) => {
      try {
        const res = await api.getUserInfo(abortController);
        dispatch({
          type: GET_LOGGED_IN_USER,
          payload: {
            id: res.data.sub,
            username: res.data.preferred_username,
            name: res.data.name,
            email: res.data.email,
            phone: res.data.phone,
            countryCode: res.data.countryCode,
          },
        });
      } catch (err) {
        if (abortController.signal.aborted) return;
        dispatch({
          type: LOGGED_IN_USER_ALERT,
          payload: {
            msg: t("loggedInUser.getLoggedInUserErrorMsg"),
            severity: "error",
          },
        });
      }
    },
    [t],
  );

  const clearLoggedInUserAlert = () =>
    dispatch({ type: CLEAR_LOGGED_IN_USER_ALERT });

  return (
    <LoggedInUserContext.Provider
      value={{
        loggedInUser: state.loggedInUser,
        loggedInUserAlert: state.loggedInUserAlert,
        getLoggedInUser,
        clearLoggedInUserAlert,
      }}
    >
      {props.children}
    </LoggedInUserContext.Provider>
  );
};

LoggedInUserState.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LoggedInUserState;
