import React, { useReducer } from "react";
import SendingSpinnerContext from "./sendingSpinnerContext";
import SendingSpinnerReducer from "./sendingSpinnerReducer";
import { SET_PERCENT } from "../types";
import PropTypes from "prop-types";

const SendingSpinnerState = ({ children }) => {
  const initialState = {
    percent: 0,
  };

  const [state, dispatch] = useReducer(SendingSpinnerReducer, initialState);

  const setPercent = (percent) =>
    dispatch({ type: SET_PERCENT, payload: percent });

  return (
    <SendingSpinnerContext.Provider
      value={{
        percent: state.percent,
        setPercent,
      }}
    >
      {children}
    </SendingSpinnerContext.Provider>
  );
};

SendingSpinnerState.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SendingSpinnerState;
