import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import AdminMultiLineTextMapper from "./AdminMultiLineTextMapper";

const PREFIX = "AdminDetailRowMapper";

const classes = {
  root: `${PREFIX}-root`,
  underlined: `${PREFIX}-underlined`,
};

const Root = styled("table")(({ theme }) => ({
  [`&.${classes.root}`]: {
    borderSpacing: "0px",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    [theme.breakpoints.up("sm")]: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "19px",
    },
    color: theme.palette.viduxColors.grey_6,
    "& th": {
      width: 0,
      textAlign: "left",
      verticalAlign: "top",
      paddingRight: "10px",
      fontWeight: 800,
      whiteSpace: "nowrap",
    },
    "& td": {
      verticalAlign: "top",
    },
    "& td div": {
      wordBreak: "break-word",
    },
  },
  [`& .${classes.underlined}`]: {
    textDecoration: "underline",
  },
}));

const AdminDetailRowMapper = ({ table }) => {
  const Value = useCallback(
    ({ underlined, value }) =>
      underlined ? (
        <div className={classes.underlined}>{value}</div>
      ) : (
        <AdminMultiLineTextMapper value={value} />
      ),
    [],
  );

  return (
    <Root className={classes.root}>
      <tbody>
        {table.map(({ name, value, isUnderlined = false }, index) => (
          <tr key={index}>
            <th>{name}</th>
            <td>
              <Value value={value} underlined={isUnderlined} />
            </td>
          </tr>
        ))}
      </tbody>
    </Root>
  );
};

AdminDetailRowMapper.propTypes = {
  table: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      isUnderlined: PropTypes.bool,
    }),
  ),
};

export default AdminDetailRowMapper;
